import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";

export default class NeedsAssessmentIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <PageHeader title={"Free Needs Assessment"} paintColor={"purple"} />
        <section class="section content">
          <div className="container">
            <div className="column is-10 is-offset-1">
              <p className="is-size-5">
                Please complete this form and include as many details regarding
                your upcoming trade show, your company, and goals for your
                exhibit and we will create a tailored solution for your upcoming
                exhibition.
              </p>
              <ul className="is-size-5">
                <li>
                  Let us suggest the most cost effective solution for your next
                  trade show, corporate, or private event
                </li>
                <li>
                  We will work with you to design your trade show booth to
                  maximize your reach with your audience.{" "}
                </li>
                <li>
                  We will provide access to our budget worksheet to help manage
                  your spend in the most cost effective manner possible
                </li>
              </ul>
              <form
                name="needs-assessment"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <h2 className="is-size-5">About Your Company</h2>
                <input
                  type="hidden"
                  name="form-name"
                  value="needs-assessment"
                />
                <div className="field">
                  <label className="label is-size-6">Name:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your name"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label is-size-6">Email:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="email"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">Company Name:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your company name"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">Company Size:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your company size"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">Role in Company:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your company role"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">
                    Product or service you provide:
                  </label>
                  <div className="control">
                    <textarea
                      className="input"
                      type="textarea"
                      placeholder="Please describe your product or service"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label is-size-6">Target Audience:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="What's your target audience"
                    />
                  </div>
                </div>

                <h2 className="is-size-5">About Your Booth:</h2>
                <div className="field">
                  <label className="label is-size-6">Show or Event Name:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your show or event name"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">
                    Date of the show or event:
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter the date pf your show"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">
                    Average number of shows you participate in yearly:
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="How many shows do you participate in yearly"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">Size of your booth:</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="What size is your booth"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">
                    Budget for this project:
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Please enter your estimated budget"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label is-size-6">
                    What does your budget include:
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Booth Rentals
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Display Graphics
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Labor (installation & dismantle)
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Furniture rentals
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Audio & Visual
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Transporation & Storage
                  </label>
                  <label className="checkbox">
                    <input type="checkbox" />
                    Other
                  </label>
                </div>

                <div className="field">
                  <div className="control">
                    <button className="btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
